@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";
@import '~styles/variables/index';


.basket-with--dots {
    &::before {
        content: '';
        background: url('app/components/MobileDishDetails/img/dotted.img.svg') center center repeat-x;
        @include all-props((
            width: 100%,
            z-index: 5,
            position: absolute,
            height: 3px,
            bottom: 2px,
        ));
    }
}

.fake-border {
    width: 100%;
    @include all-props((
        height: (
            0: 52px,
            375: 46px,
        ),
    ));
    background-color: var(--color__coconut);
}

// COMMON

.new-dish-details-root {
    position: fixed;
    background-color: var(--color__coconut);
    height: 100%;
    z-index: 3000;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    @include mobile-props((
        width: 100vw,
    ));

    @include desktop-props((
        width: 452px,
        right: 0,
    ));
}

.new-dish-details-static-text {
    & :global(.bold-text) {
        font-weight: 600;
    }

    @include all-props((
        padding: (
            0: 28px 16px,
            375: 37px 20px 40px 20px,
            1024: 37px 20px 40px 20px,
        ),
        font-family: $gilroy,
        color: #9C9C9C,
        font-weight: 400,
        font-size: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        line-height: (
            0: 19px,
            375: 23px,
            1024: 23px,
        ),
    ))
}

.new-details-card-root.halloween {
    @include all-props((
        padding-bottom: (
            0: 2px,
            375: 3px,
        ),
    ));
    & .new-details-card-title {
        color: #83533B;
    }

    & .new-details-card-info {
        border: 1px solid #E5D1C7;
    }

    & .new-details-card-info-line-one {
        color: #83533B;
    }
    & .new-details-card-info-line-three {
        color: #83533B;
    }

    & .basket-with--dots {
        &::before {
            background: url('app/components/MobileDishDetails/img/dottedhlwn.img.svg') center center repeat-x;
        }
    }

}

/* NewDishDetailsCard */

.new-details-card-root {
    background-color: var(--color__coconut);
    @include all-props((
        padding-bottom: (
            0: 20px,
            375: 28px,
            1024: 28px,
        ),
    ));
    &.box {
        @include all-props((
            padding-bottom: (
                0: 14px,
                375: 18px,
                1024: 18px,
            )
        ));
    }
}

.new-details-card-img {
    @include all-props((
        margin-bottom: (
            0: 12px,
          375: 15px,
          1024: 15px,
        ),
        position: relative,
    ))
}

@keyframes stroboscope {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.new-details-card-animation-wrapper {
    @include mobile-props((
        position: relative,
        overflow: hidden,
    ));

    @include responsive-mobile-props(375px, (
        height: 291px,
    ));
}

.new-details-card-animation {
    @include mobile-props((
        position: absolute,
        left: 0,
        top: 0,
    ));

    &:last-child {
        animation: stroboscope 3s infinite;
    }
}

.new-details-card-img__item {
    @include all-props((
        width: 100%,
    ))
}

.new-details-card-close {
    @include all-props((
        position: fixed,
        z-index: 3001,
        padding: 5px,
        padding-top: 10px,
        padding-right: 10px,
        padding-bottom: 35px,
        padding-left: 35px,
        top: (
            0: 5px,
            375: 10px,
            1024: 10px,
        ),
        right: (
            0: 5px,
            375: 10px,
            1024: 10px,
        ),
    ));

    &.no-fixed {
        position: absolute;
    }
}


.new-details-card-close__item {
    @include all-props((
        width: (
            0: 22px,
            375: 28px,
            1024: 28px,
        ),
        height: (
            0: 22px,
            375: 28px,
            1024: 28px,
        ),
    ));
}

.new-details-card-title {
    @include all-props((
        font-family: $gilroy,
        font-weight: 500,
        color: #424242,
        font-size: (
            0: 17px,
            375: 19px,
            1024: 19px,
        ),
        line-height: (
            0: 20px,
            375: 21px,
            1024: 21px,
        ),
        letter-spacing: (
            0: -0.36px,
            1024: -0.36px,
        ),
        margin: (
            0: 0 16px 13px 16px,
            375: 0 20px 18px 20px,
            1024: 0 20px 18px 20px,
        ),
    ));
}

.new-details-card-info {
    @include all-props((
        margin: (
            0: 0 16px,
            375: 0 20px,
            1024: 0 20px,
        ),
        border: 1px solid #EDEDED,
        border-radius: (
            0: 14px,
            375: 16px,
            1024: 16px,
        ),
        padding: (
            0: 18px 18px 17px 20px,
            375: 22px 20px 24px 20px,
            1024: 22px 20px 24px 20px,
        ),
    ));
    &.box {
        @include all-props((
            margin-bottom: (
                0: 28px,
                375: 36px,
                1024: 36px,
            ),
        ));
    }
    &.romantic {
        @include all-props((
            margin-left: 0,
            margin-right: 0,
            margin-bottom: (
                0: 28px,
                375: 36px,
                1024: 36px,
            ),
        ))
    }
}

.new-details-card-info-line {
    @include all-props((
        padding-bottom: (
            0: 13px,
            375: 12px,
            1024: 12px,
        ),
        display: flex,
        align-items: flex-end,
        font-size: (
            0: 15px,
            375: 17px,
            1024: 17px,
        ),
        letter-spacing: (
            0: -0.2px,
            375: -0.38px,
            1024: -0.38px,
        ),
        line-height: (
            0: 15px,
            375: 18px,
            1024: 18px,
        ),
        font-family: $gilroy,
        font-weight: 500,
        color: #747474,
    ));


    &:nth-last-child(1) {
        padding-bottom: 0px,
    }

}

.new-details-card-info-line-one {
    @include all-props((
        margin-right: (
            0: 2px,
            375: 3px,
            1024: 3px,
        ),
    ));
    &.limited {
        @include mobile-props((
            max-width: 59vw,
        ))
    }
}

// 0.58974358974

.new-details-card-info-line-two {
    flex: 1;
    position: relative;
}

.new-details-card-info-line-three {
    span {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        line-height: inherit;
    }

    & .divider {
        padding-right: 3px,
    }

    @include all-props((
        margin-left: (
            0: 2px,
            375: 3px,
            1024: 3px,
        ),
    ));
}

.new-details-card-info-digit-value {
    @include mobile-props((
        font-family: $haas-txpro,
    ))
}

.new-details-card-info-text {
    @include mobile-props((
        font-family: $gilroy,
    ))
}

/* NewDishDetailsComposition */

.new-details-composition-root {
    @include all-props((
        background-color: #f5f5f5,
        padding: (
            0: 28px 16px,
            375: 37px 20px 40px 20px,
            1024: 37px 20px 40px 20px,
        ),
    ));
}

.new-details-composition-title {
    @include all-props((
        font-family: $gilroy,
        font-weight: 800,
        font-size: (
            0: 19px,
            375: 21px,
            1024: 21px,
        ),
        line-height: (
            0: 23px,
            375: 23px,
            1024: 23px,
        ),
        letter-spacing: (
            0: -0.4px,
            375: -0.6px,
            1024: -0.6px,
        ),
        color: #4F4F4F,
        padding-bottom: (
            0: 3px,
            375: 5px,
            1024: 5px,
        ),
    ));
}

.new-details-composition-text {
    @include all-props((
        font-family: $gilroy,
        color: #9C9C9C,
        font-weight: 400,
        font-size: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        line-height: (
            0: 19px,
            375: 23px,
            1024: 23px,
        ),
    ));

    &:first-letter {
        text-transform: uppercase;
    }
}
