@import '~styles/variables/index';
@import '../variables.scss';


$dish-description-padding__320: 8px 0 0;
$dish-description-padding__375: 14px 0 0;

$basket-button-width__320: 100px;
// $basket-button-width__375: 112px;
$basket-button-width__375: 110px;

.is-soldout {
    color: var(--color--lichi);
}

.mobile-dish-desc__top-row {
    @include all-props((
        display: flex,
        align-items: flex-start,
        justify-content: space-between,
        width: 100%,
        padding: (
            0: $dish-description-padding__320,
            375: $dish-description-padding__375,
            1024: $dish-description-padding__375,
        ),
    ));
    &.small {
        @include mobile-props((
            padding-top: (
                0: 8px,
                375: 10px,
            )
        ))
    }
}

.mobile-dish-desc__desc-container {
    @include mobile-props((
        display: table,
        table-layout: fixed,
        width: (
            0: calc(100% - #{$basket-button-width__320}),
            375: calc(100% - #{$basket-button-width__375}),
            1024: calc(100% - #{$basket-button-width__375}),
        )
    ));
}

.mobile-dish-desc__title-container {
    @include all-props((
        width: 100%,
        padding-right: 8px,
        text-align: left,
        height: (
            0: 40px,
            375: 46px,
        ),
    ));
}

.mobile-dish-desc__button-container {
    @include all-props((
        flex: none,
        width: (
            0: 98px,
            375: 110px,
            414: 113px,
            1024: 113px,
        ),
    ));

}

.mobile-dish-desc__additional-info-container {
    @include all-props((
        display: flex,
        justify-content: flex-start,
        align-items: baseline,
        padding: 0,
    ));
}

.mobile-dish-desc__cooking-time-container {
    @include all-props((
        display: flex,
        align-items: center,
        width: 100%,
        margin-top: 2px,
    ));

    &.is-soldout::before {
        content: '';
        @include all-props((
            background-color: var(--color__melon),
        ));
    }

    &::before {
        content: '';
        @include all-props((
            background-color: var(--color__egg),
            border-radius: 100%,
            margin-right: (
                0: 6px,
                1024: 6px,
            ),
            width: (
                0: 10px,
                375: 12px,
                414: 14px,
                1024: 14px,
            ),
            height: (
                0:10px,
                375: 12px,
                414: 14px,
                1024: 14px,
            ),
        ));
    }
}

.mobile-dish-desc__cooking-time-texts {
    display: flex;
    flex-grow: 2;
    align-items: baseline;
}

.mobile-dish-desc__additional {
    @include all-props((
        color: var(--color__lichi),
    ));
}
