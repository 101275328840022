@import '~styles/variables/index';

.dishDetailsSetDescriptionRoot {
    @include mobile-props((
        box-sizing: border-box,
        padding: (
            0: 0 16px,
            375: 0 20px,
        ),
        font-family: $gilroy,
        font-style: normal,
        color: var(--color__pepper)
    ))
}

.dishDetailSetDescriptionItem {
    @include mobile-props((
        margin-bottom: (
            0: 60px,
            375: 80px,
        )
    ));
    &:last-child {
        @include mobile-props((
            margin-bottom: 40px,
        ))
    }
}

.dishDetailsSetDescriptionItemTitle {
    @include mobile-props((
        font-size: (
            0: 19px,
            375: 21px,
        ),
        line-height: 23px,
        font-weight: 800,
        letter-spacing: -0.4px,
        margin-bottom: 8px,
    ))
}

.dishDetailsSetDescriptionItemImageContainer {
    @include mobile-props((
        width: 100%,
        height: (
            0: 330px,
            375: 350px,
        ),
        margin-bottom: (
            0: 8px,
            375: 10px,
        ),
        border-radius: (
            0: 30px,
            375: 40px,
        ),
        background-size: cover,
        background-position: center,
    ));
}

.dishDetailsSetDescriptionItemImageContainerLarge {
    @include mobile-props((
        height: (
            0: 432px,
            375: 525px,
        ),
    ))
}

.dacha_delivered {
    background-image: url('../DishDetailsSetDescription/img/dacha/delivered_mobile.jpg');
}
.dacha_inside {
    background-image: url('../DishDetailsSetDescription/img/dacha/inside_mobile.jpg');
}
.dacha_cooked {
    background-image: url('../DishDetailsSetDescription/img/dacha/cooked_mobile.jpg');
}

.dishDetailsSetDescriptionDescTitle {
    @include mobile-props((
        font-size: (
            0: 16px,
            375: 19px,
        ),
        line-height: (
            0: 17px,
            375: 20px,
        ),
        margin-bottom: (
            0: 8px,
            375: 10px,
        ),
        font-weight: 500,
        letter-spacing: -0.361px,
        color: var(--color__pepper)
    ))
}

.dishDetailsSetDescriptionListItem {
    @include mobile-props((
        display: flex,
        gap: (
            0: 9px,
            375: 10px,
        ),
        font-size: (
            0: 16px,
            375: 19px,
        ),
        line-height: 104%,
        font-weight: 400,
        letter-spacing: -0.5px,
        color: var(--color__coal)
    ));
}

.dishDetailsSetDescriptionListItemBullet {
    @include mobile-props((
        flex-shrink: 0,
        margin-top: 5px,
        width: (
            0: 4px,
            375: 6px,
        ),
        height: (
            0: 4px,
            375: 6px,
        ),
        aspect-ratio: 1,
        border-radius: 100%,
        background-color: var(--color__coal),
    ))
}

.dishDetailsSetDescriptionParagraph {
    @include mobile-props((
        display: block,
        font-size: (
            0: 16px,
            375: 19px,
        ),
        line-height: 104%,
        font-weight: 400,
        letter-spacing: -0.5px,
        color: var(--color__coal)
    ));
}


