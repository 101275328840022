@import '~styles/variables/index';


.button-bottom-area__root {
    @include desktop-props((
        max-width: inherit,
    ));


    @include all-props((
        box-sizing: border-box,
        width: 100%,
        position: fixed,
        bottom: 0,
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.05), // var(--color__coconut)
        background-color: var(--color__coconut),
        padding:  (
            0: 11px 16px,
            360: 12px 16px,
            375: 12px 20px,
            1024: 12px 20px,
        )
    ));

    &.fixed {
        position: fixed;
        @include mobile-props((
            transform: translate(-50%),
            right: 50%,
            left: 50%,
            max-width: 444px,
        ));

    }

    &.static {
        position: static,
    }
}
