@import '~styles/variables/index';

.burgerMenuV2Root {
    @include mobile-props((
        background-color: var(--color__salt),
        padding: (
            0: 16px,
            375: 20px,
        ),
        min-height: (
            0: calc(100vh - 52px),
            375: calc(100vh - 60px),
        ),
    ));
}

/* LoginRow */
.burgerLoginRowRoot {
    @include mobile-props((
        padding: (
            0: 13px,
        ),
        margin-bottom: (
            0: 9px,
            375: 10px,
        ),
        display: flex,
        align-items: center,
        gap: (
            0: 8px,
            375: 10px,
        ),
        width: 100%,
    ));
}
.burgerLoginRowIcon {
    @include mobile-props((
        margin-left: (
            0: 7px,
        ),
        height: (
            0: 30px,
            375: 32px,
        ),
        width: (
            0: 30px,
            375: 32px,
        ),
    ));
}

.burgerLoginRowIconFrame {
    height: inherit;
    width: inherit;
    @include mobile-props((
        border-radius: 6px,
    ));
}

.burgerLoginRowIconItem {
    height: inherit;
    width: inherit;
}

.burgerLoginRowText {
    @include mobile-props((
        color: var(--color__coal),
        font-family: $gilroy,
        font-size: (
            0: 16px,
            375: 17px,
        ),
        font-weight: 500,
        line-height: (
            0: 17px,
            375: 18px,
        ),
        letter-spacing: -0.2px,
    ));
}
/* SetBannerRow */
.setBannerRowRoot {
    @include mobile-props((
        margin-bottom: (
            0: 8px,
            375: 10px,
        ),
        background-color: #fff,
        border-radius: inherit,
        width: 100%,
        display: block,
    ));
}

.setBannerRowBackground {
    @include mobile-props((
        border-radius: inherit,
        background-size: cover,
        background-position: center top,
        background-repeat: no-repeat,
        background-image: url('./img/5x2banner.jpg'),
        width: 100%,
    ));

    &.film {
        background-image: url('./img/filmBanner.jpg'),
    }
    &.halloween {
        background-image: url('./img/halloweenBanner.jpg'),
    }
    &.hp {
        background-image: url('./img/hpBanner.jpg'),
    }
    &.maxim {
        background-image: url('./img/maximBanner.jpg'),
    }
    &.ny {
        background-image: url('./img/nyBanner.jpg'),
    }
    &.romantic {
        background-image: url('./img/romanticBanner.jpg'),
    }
    &.maslenitsa {
        background-image: url('./img/maslenitsa.jpg'),
    }

    @include responsive-mobile-props-limited(320px,(
        height: 86px,
    ), 375px, 320px, 375px);

    @include responsive-mobile-props-limited(375px,(
        height: 100px,
    ), 444px, 375px);

    @include mobile-props((
        height: (
            444: 120.6px,
        ),
    ));
}

.infoTitle {
    font-family: $gilroy;
    font-weight: 600;
    color: #FFFFFF;
    text-align: start;
    @include mobile-props((
        letter-spacing: -0.2px,
        font-size: 16px,
        line-height: 16px,
    ));
    @include responsive-mobile-props-limited(375px,(
        font-size: 18px,
        line-height: 18px,
    ), 444px, 375px);
}

.infoDescription {
    font-family: $gilroy;
    font-weight: 400;
    color: #FFFFFF;
    text-align: start;
    @include mobile-props((
        letter-spacing: -0.2px,
        font-size: 14px,
        line-height: 14px,
        padding-bottom: 5px,
    ));
    @include responsive-mobile-props-limited(375px,(
        margin-bottom: 6px,
        font-size: 15px,
        line-height: 15px,
        padding-bottom: 7px,
    ), 444px, 375px);
}

.cutoutText {
    font-family: $haas-dsstd;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: black;
    background-color: white;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile-props((
        width: 61.5px,
        height: 20px,
        padding-left: 8px,
        padding-right: 8px,
        border-radius: 6px,
        font-size: 14px,
        line-height: 14px,
    ));

    @include responsive-mobile-props-limited(375px,(
        width: 64px,
        height: 22px,
        padding-left: 8px,
        padding-right: 8px,
        border-radius: 8px,
        font-size: 15px,
        line-height: 15px,
    ), 444px, 375px);

    @include mobile-props((
        line-height: (
            418: normal,
        ),
    ));

}

.priceText {
    color: #FF710B;
    &::after {
        content: "₽";
        padding-left: 1px;
        font-family: $gilroy;
        font-weight: 600;
        letter-spacing: -0.057px;
    }
}

/* FoldingMenuRow */
.foldingMenuRowRoot {
    @include mobile-props((
        margin-bottom: (
            0: 8px,
            375: 10px,
        ),
        padding: (
            0: 20px,
        ),
    ));
}

.foldingMenuItemsContainer {

}
.foldingMenuDividerWrapper {
    @include mobile-props((
        padding: (
            0: 14px 0px,
            375: 16px 0px,
        ),
    ));

    &:nth-child(-n + 1) {
        @include mobile-props((
            padding-top: 0,
            padding-bottom: (
                0: 13px,
                375: 15px,
            ),
            border-bottom: 1px solid var(--color__grey-salt),
        ));
    }

    &:nth-last-child(-n + 1) {
        @include mobile-props((
            padding-bottom: 0,
            padding-top: (
                0: 13px,
                375: 15px,
            ),
            border-top: 1px solid var(--color__grey-salt),
        ));
    }
}

.foldingMenuTitleButton {
    @include mobile-props((
        width: 100%,
        box-sizing: border-box,
        text-align: start,
        display: flex,
        align-items: center,
        justify-content: space-between,
    ));
}

.foldingMenuTitleButtonText {
    @include mobile-props((
        color: var(--color__coal),
        font-family: $gilroy,
        font-size: (
            0: 16px,
            375: 17px,
        ),
        font-weight: 500,
        line-height: (
            0: 17px,
            375: 18px,
        ),
        letter-spacing: -0.2px,
    ));
}

.foldingMenuTitleButtonIcon {
    @include mobile-props((
        height: (
            0: 14px,
            375: 16px,
        ),
        width: (
            0: 14px,
            375: 16px,
        ),
    ));

    &.open {
        transform: rotate(-90deg);
    }

    &.close {
        transform: rotate(90deg);
    }
}

.foldingMenuItemDivider {
    @include mobile-props((
        height: 1px,
        width: 100%,
        background-color: red,
        padding: (
            0: 14px 0,
            375: 15px 0,
        )

    ));
}

.foldingMenuList {
    @include mobile-props((
        padding-right: (
            0: 14px,
            375: 16px,
        ),
        padding-top: (
            0: 14px,
            375: 16px,
        )
    ));
}
.foldingMenuListItem {
    @include all-props((
        position: relative
    ));
    @include mobile-props((
        color: var(--color__coal),
        font-family: $gilroy,
        font-size: (
            0: 16px,
            375: 17px,
        ),
        font-weight: 500,
        line-height: (
            0: 17px,
            375: 18px,
        ),
        letter-spacing: -0.2px,
        padding-left: 30px,
        padding-bottom: (
            0: 14px,
            375: 16px,
        ),
        overflow: hidden,
        white-space: nowrap,
        text-overflow: ellipsis,
    ));

    &:nth-last-child(-n + 1) {
        padding-bottom: 0;
    }
}

.hasBadge {
    @include mobile-props((
        padding-top: (
            0: 14px,
            375: 16px,
        ),
        padding-left: 63px,
    ))
}

.badgeWrapper {
    @include mobile-props((
        position: absolute,
        top: (
            0: 13px,
            375: 15px
        ),
        left: 29px,
    ))
}
/* AboutRow */
.aboutRowRoot {
    @include mobile-props((
        padding: (
            0: 20px,
        ),
        margin-bottom: (
            0: 16px,
            375: 20px,
        ),
    ));
}

.aboutRowButton {
    @include mobile-props((
        width: 100%,
        display: flex,
        align-items: center,
        justify-content: space-between,
    ));
}

.aboutRowText {
    @include mobile-props((
        color: var(--color__coal),
        font-family: $gilroy,
        font-size: (
            0: 16px,
            375: 17px,
        ),
        font-weight: 500,
        line-height: (
            0: 17px,
            375: 18px,
        ),
        letter-spacing: -0.2px,
    ));
}

// footer
.burgerMenuV2FootRoot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    @include mobile-props((
        margin-bottom: (
            0: 63px,
        ),
    ));

}

.burgerMenuV2TimeOpen {
    @include mobile-props((
        color: var(--color__hering),
        font-family: $gilroy,
        font-size: (
            0: 16px,
            375: 17px,
        ),
        font-style: normal,
        font-weight: 400,
        line-height: (
            0: 17px,
            375: 20px,
        ),
        letter-spacing: -0.2px,
    ));
}

.burgerFootSocialLinkWrapper {
    display: flex;
    gap: 8px,
}

.burgerFootSocialLinkItem {
    @include mobile-props((
        transform: translate(0px, -2px),
        width: (
            0: 28px,
            375: 36px,
        ),
        height: (
            0: 28px,
            375: 36px,
        ),
    ));

    & img {
        height: inherit;
        width: inherit;
    }

}
